import "./App.css";
import Nav from "./components/Nav";
import Description from "./components/Description";
import Elements from "./components/Elements";
import Services from "./components/Services";
import Partnership from "./components/Partnership";
import Catalyst from "./components/Catalyst";
import Team from "./components/Team";
import team1 from "./assets/apex1.jpg";
import team2 from "./assets/apex2.jpg";
import team3 from "./assets/apex3.jpg";
import Footer from "./components/Footer";

function App() {
  const content = {
    title: "ARTIFICIAL INTELLIGENCE",
    gradientTitle: "ENGINEER",
    subtitle: "APEX: Precision Meets Innovation",
    text: "At APEX, we weave the intricate tapestry of technology with Japan's age-old dedication to precision and craftsmanship. As pioneers in the realm of artificial intelligence, our legacy is deeply rooted in offering paramount annotating, machine learning engineering, and programming services. Every solution we engineer is a symphony of meticulous Japanese quality and avant-garde innovation. Our relentless commitment to excellence has positioned us at the forefront, trusted by global partners who seek nothing but the best. We believe in the confluence of tradition and technology, ensuring that every project we undertake resonates with unparalleled accuracy and efficiency.",
    closingText:
      "Journey with APEX, and let's shape the future with world-class intelligence, infused with the trusted essence of Japanese mastery.",
  };

  const content2 = {
    title: "ABOUT",
    gradientTitle: "Apex",
    subtitle: "At APEX, we are more than service providers",
    text: "We are trusted partners in facilitating the establishment of a proficient remote workforce specializing in data development. We offer highly qualified Data Annotators and Full-Stack Engineers committed to your project's prosperity. Clients can scale their data labeling teams as needed.",
  };

  const content3 = {
    title: "OUR GUIDING",
    gradientTitle: "PRINCIPLES",
    subtitle: "Our core principles serve as our foundation",
    text:
      "Customer-Centric. Our paramount focus is accommodating your needs, fostering trust, and building enduring partnerships.\n" +
      "\n" +
      "Quality. We consistently deliver services that meet and exceed client expectations, maintaining rigorous standards.\n" +
      "\n" +
      "Collaboration. We engage closely with clients to realize shared objectives through open communication and active partnership.\n" +
      "\n" +
      "Adaptability. We remain flexible to adjust to shifting client needs, market dynamics, and technological advances\n",
  };

  const content4 = {
    title: "WHY CHOOSE OUTSOURCING",
    gradientTitle: "SOLUTIONS?",
    subtitle:
      "Considering APEX Outsourcing Solutions presents several compelling advantages",
    text: "Cost Efficiency. Eliminate the complexities of in-house hiring and training. Infrastructure Optimization. Reduce infrastructure expenses through our expertise. Exceptional Expertise. Access professionals specializing in network management and IT. Customized Flexibility. Tailor services to your evolving IT demands",
  };

  const content5 = {
    title: "MEET OUR FOUNDING",
    gradientTitle: "TEAM",
    subtitle:
      "Allow us to introduce the leaders behind APEX Outsourcing Solutions",
  };

  const content6 = {
    title: "JOIN",
    gradientTitle: "US",
    subtitle: "Let us work together",
    text: "We are thrilled and absolutely grateful that you've chosen to explore the world of APEX Outsourcing Solutions with us. Suppose you are eager to optimize your business. In that case, we welcome you to connect through formal channels to explore how we can serve you better.",
  };

  return (
    <>
      <div className="App">
        <div className="z-50 max-w-full">
          <Nav />

          <div className="bg-lavender flex max-w-screen items-center h-[calc(110vh-6rem)] md:h-[calc(100vh-6rem)]">
            <div className="flex md:justify-items-center md:items-center">
              <div className="flex-1">
                <Description
                  title={content.title}
                  gradientTitle={content.gradientTitle}
                  subtitle={content.subtitle}
                  text={content.text}
                  closingText={content.closingText}
                />
              </div>
              <div className="flex-1 md:block hidden">
                <Elements />
              </div>
            </div>
          </div>

          <div
            id="about"
            className="scroll-my-24 bg-ghostwhite flex max-w-screen items-center h-[calc(100vh-6rem)] md:h-[calc(100vh-6rem)]"
          >
            <div className="flex md:justify-items-center md:items-center">
              <div className="flex-1 md:block hidden">
                <Elements />
              </div>
              <div className="flex-1">
                <Description
                  title={content2.title}
                  gradientTitle={content2.gradientTitle}
                  subtitle={content2.subtitle}
                  text={content2.text}
                />
              </div>
            </div>
          </div>

          <div className="bg-lavender flex max-w-screen items-center h-[calc(120vh-6rem)] md:h-[calc(100vh-6rem)]">
            <div className="flex flex-col md:flex-row md:justify-items-center md:items-center">
              <div className="flex-1">
                <Description
                  title={content3.title}
                  gradientTitle={content3.gradientTitle}
                  subtitle={content3.subtitle}
                  text={content3.text}
                />
              </div>

              <div className="flex-1 my-3 ml-24 md:ml-0 justify-center items-center">
                <div className="flex justify-center items-center aspect-video w-3/4 md:w-3/4">
                  <iframe
                    className="w-full h-full"
                    src="https://www.youtube.com/embed/S6F6nMOPESo?autoplay=1&amp;mute=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          <div
            id="services"
            className="scroll-my-24 bg-ghostwhite flex max-w-screen items-center h-[calc(120vh-6rem)] md:h-[calc(160vh-6rem)]"
          >
            <div className="flex flex-1">
              <Services />
            </div>
          </div>

          <div
            id="partnership"
            className="scroll-my-24 bg-lavender flex max-w-screen items-center h-[140vh] md:h-[calc(100vh-6rem)]"
          >
            <div className="flex flex-1 items-center justify-center m-4">
              <Partnership />
            </div>
          </div>

          <div
            id="work"
            className="scroll-my-24 bg-ghostwhite flex flex-col max-w-screen items-center h-[100vh] md:h-[calc(120vh-6rem)]"
          >
            <div className="flex flex-0 justify-center mt-20">
              <div className="flex z-30">
                <span className="text-2xl md:text-5xl uppercase font-semibold leading-tight">
                  MAIN CATALYSTS FOR{" "}
                  <span className="text-2xl md:text-5xl uppercase bg-custom-gradient font-semibold text-transparent bg-clip-text leading-tight ">
                    SUCCESS
                  </span>
                </span>
              </div>
            </div>
            <div className="flex-1 flex w-full">
              <Catalyst />
            </div>
            <div className="hidden md:block absolute justify-items-center items-center justify-center mt-36">
              <div className="animate-bounce-slow absolute flex-none bg-skyblue w-64 h-64 rounded-full blur-xl ml-[-2rem] mt-[23rem] z-10"></div>
              <div className="animate-bounce-slow flex-none bg-vector-gradient w-96 h-96 rounded-full blur-lg ml-[0rem] mt-[10rem] z-20"></div>
              <div className="animate-bounce-slow absolute flex-none bg-turquoise w-64 h-64 rounded-full blur-xl ml-[14rem] mt-[-31rem] z-10"></div>
            </div>
          </div>
          <div
            id="founders"
            className="scroll-my-24 bg-lavender flex max-w-screen items-center h-[calc(100vh-6rem)] md:h-[calc(100vh-6rem)]"
          >
            <div className="flex flex-col md:flex-row md:justify-items-center md:items-center h-full">
              <div className="flex max-w-2xl mt-10">
                <Team
                  title={content5.title}
                  gradientTitle={content5.gradientTitle}
                  subtitle={content5.subtitle}
                  text={content5.text}
                  closingText={content5.closingText}
                />
              </div>
              <div className="lg:flex-row flex flex-col max-w-4xl items-center">
                <div className="flex-1 md:w-5/12 m-4 w-7/12">
                  <img
                    className="rounded-xl shadow-2xl hover:animate-pulse"
                    src={team1}
                  />
                </div>
                <div className="flex-1 m-4 md:w-5/12 w-7/12">
                  <img
                    className="rounded-xl shadow-2xl hover:animate-pulse"
                    src={team2}
                  />
                </div>
                <div className="flex-1 m-4 md:w-5/12 w-7/12">
                  <img
                    className="rounded-xl shadow-2xl hover:animate-pulse"
                    src={team3}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="scroll-my-24 bg-lavender flex max-w-screen items-center h-[calc(80vh-6rem)] md:h-[calc(100vh-6rem)]">
            <div className="flex md:justify-items-center md:items-center">
              <div className="flex-1">
                <Description
                  title={content6.title}
                  gradientTitle={content6.gradientTitle}
                  subtitle={content6.subtitle}
                  text={content6.text}
                />
              </div>
              <div className="flex-1 md:block hidden">
                <Elements />
              </div>
            </div>
          </div>

          <div
            id="footer"
            className="scroll-my-24 bg-ghostwhite flex max-w-screen items-center h-[calc(60vh-6rem)] md:h-[calc(59vh-6rem)]"
          >
            <div className="md:mx-28 mx-8 flex flex-1 justify-between">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
