import logo from "../assets/apex-primary-1@2x.png";
import phone from "../assets/vector7.svg";
import email from "../assets/vector8.svg";
import address from "../assets/vector9.svg";

function Footer() {
  const handleEmailClick = () => {
    window.location.href = "mailto:info@apex.tokyo";
  };

  return (
    <>
      <div className="flex flex-col justify-items-start text-left space-y-10">
        <div className="flex-1 w-4/12">
          <img src={logo} />
        </div>
        <div className="flex-1">
          <h1 className="text-5xl font-semibold">CONNECT WITH US</h1>
        </div>
        <div className="flex-1 text-xs leading-tight space-y-1">
          <div className="flex-1 flex">
            <img className="mr-3 w-3" src={phone} />
            <h1 className="hover:animate-pulse">+81-3-6822-8754</h1>
          </div>
          <div className="flex-1 flex">
            <img className="mr-3 w-3" src={email} />

            <a href="mailto:info@apex.tokyo">
              <h1 className="hover:animate-pulse">info@apex.tokyo</h1>
            </a>
          </div>
          <div className="flex-1 flex">
            <img className="mr-3 w-3" src={address} />

            <h1 className="hover:animate-pulse">
              5F Eclat Shibuya, 3-6-2 Shibuya, Shibuya-ku, Tokyo 150-0002
            </h1>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex flex-col text-left font-semibold text-xs space-y-2 justify-items-end">
        <a href="#">Home</a>
        <a href="#about">About</a>
        <a href="#services">Services</a>
        <a href="#partnership">Partnership</a>
        <a href="#work">Work</a>
        <a href="#founders">Founders</a>
      </div>

      <div className="flex flex-col text-left">
        <a>Send Us a Message and build partnership with us today!</a>
        <div className="flex items-center justify-items-center sm:items-start sm:justify-items-start">
          <button
            onClick={handleEmailClick}
            className="transition duration-700 ease-in-out font-light text-xs font-semibold px-8 py-3 bg-cadetblue text-ghostwhite mt-4 hover:bg-transparent hover:text-cadetblue border border-cadetblue hover:border-cadetblue rounded-lg"
          >
            Send Message
          </button>
        </div>
      </div>
    </>
  );
}

export default Footer;
