import logo from "../assets/apex-icon-1@2x.png";

function Partnership() {
  return (
    <>
      <div className="flex flex-col flex-1 absolute">
        <div className="flex flex-1 justify-center md:mb-20 my-10">
          <div className="flex-1 z-30">
            <span className="text-2xl md:text-5xl uppercase font-semibold leading-tight">
              Partnership{" "}
              <span className="text-2xl md:text-5xl uppercase bg-custom-gradient font-semibold text-transparent bg-clip-text leading-tight ">
                Structures
              </span>
            </span>
          </div>
        </div>
        <div className="space-y-20 md:space-y-0 mx-5 mb-4 flex flex-col md:flex-row flex-1 min-w-[calc(100vw-6rem)] md:min-w-[calc(100vw-20rem)] py-32 opacity-75 bg-ghostwhite rounded-xl drop-shadow-2xl z-20">
          <div className="mx-2 flex flex-1 flex-col items-center justify-center">
            <div className="flex flex-1 w-1/12 p-4 px-7 bg-custom-gradient text-sm font-bold rounded-xl text-white justify-center drop-shadow-2xl mb-3">
              1
            </div>
            <div className="flex flex-1 mb-3">
              <span className="text-sm font-bold">Joint Ventures</span>
            </div>
            <div className="flex flex-1">
              <span className="text-xs leading-tight font-medium">
                Collaborate with us in a profitable enterprise, sharing
                associated risks.
              </span>
            </div>
          </div>

          <div className="mx-2 flex flex-1 flex-col items-center justify-center">
            <div className="flex flex-1 w-1/12 p-4 px-7 bg-custom-gradient text-sm font-bold rounded-xl text-white justify-center drop-shadow-2xl mb-3">
              2
            </div>
            <div className="flex flex-1 mb-3">
              <span className="text-sm font-bold">Strategic Alliances</span>
            </div>
            <div className="flex flex-1">
              <span className="text-xs leading-tight font-medium">
                Undertake mutually beneficial projects while preserving
                organizational independence.
              </span>
            </div>
          </div>

          <div className="mx-2 flex flex-1 flex-col items-center justify-center">
            <div className="flex flex-1 w-1/12 p-4 px-7 bg-custom-gradient text-sm font-bold rounded-xl text-white justify-center drop-shadow-2xl mb-3">
              3
            </div>
            <div className="flex flex-1 mb-3">
              <span className="text-sm font-bold">
                Client-Centric Partnership
              </span>
            </div>
            <div className="flex flex-1">
              <span className="text-xs leading-tight font-medium">
                Embrace a culture and strategy focused on delivering superior
                customer experiences, fostering brand loyalty.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex mx-20 justify-items-center items-center justify-center">
        <div className="animate-bounce-slow absolute flex-none bg-skyblue w-52 h-52 rounded-full blur-xl ml-[-8rem] mt-[20rem] z-2"></div>
        <div className="animate-bounce-slow flex-none bg-vector-gradient w-72 h-72 rounded-full blur-lg z-10"></div>
        <div className="animate-bounce-slow absolute flex-none bg-turquoise w-44 h-44 rounded-full blur-xl ml-[14rem] mt-[-13rem] z-2"></div>
      </div>
    </>
  );
}

export default Partnership;
