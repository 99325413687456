import logo from '../assets/apex-icon-1@2x.png'

function Elements(){
    return(
       <>
           <div className="flex mx-20 justify-items-center items-center justify-center">
               <div className="ml-[-16rem] mt-[-10rem] justify-evenly absolute flex flex-none flex-row w-48 py-3 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl z-20">
                   <div className="flex ml-0">
                       <img className="w-6" src={logo}/>
                   </div>
                   <div className="flex font-medium text-xs text-center items-center">
                       Artificial Intelligence
                   </div>
               </div>

               <div className="ml-[-16rem] mt-[10rem] justify-evenly absolute flex flex-none flex-row w-36 py-3 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl z-20">
                   <div className="flex ml-0">
                       <img className="w-6" src={logo}/>
                   </div>
                   <div className="flex font-medium text-xs text-center items-center">
                       Technical
                   </div>
               </div>

               <div className="ml-[12rem] mt-[2rem] justify-evenly absolute flex flex-none flex-row w-40 py-3 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl z-20">
                   <div className="flex ml-0">
                       <img className="w-6" src={logo}/>
                   </div>
                   <div className="flex font-medium text-xs text-center items-center">
                       Engineering
                   </div>
               </div>

               <div className="ml-[5rem] mt-[22rem] justify-evenly absolute flex flex-none flex-row w-40 py-3 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl z-20">
                   <div className="flex ml-0">
                       <img className="w-6" src={logo}/>
                   </div>
                   <div className="flex font-medium text-xs text-center items-center">
                       Algorithms
                   </div>
               </div>



               <div className="animate-bounce-slow absolute flex-none bg-skyblue w-52 h-52 rounded-full blur-xl ml-[-8rem] mt-[20rem] z-2">
               </div>
               <div className="animate-bounce-slow flex-none bg-vector-gradient w-72 h-72 rounded-full blur-lg z-10">
               </div>
               <div className="animate-bounce-slow absolute flex-none bg-turquoise w-44 h-44 rounded-full blur-xl ml-[14rem] mt-[-13rem] z-2">
               </div>
           </div>

       </>
    );
}

export default Elements;