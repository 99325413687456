import logo from "../assets/apex-icon-1@2x.png";

function Catalyst() {
  return (
    <>
      <div className="flex flex-1 flex-col justify-center items-center gap-y-2 md:gap-y-14 z-40">
        <div className="flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Comprehensive Service Management
          </div>
        </div>
        <div className="flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Stringent Security Measures
          </div>
        </div>
        <div className="flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Exceptional Speed and Efficiency
          </div>
        </div>
        <div className="md:hidden flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Substantial Cost Savings
          </div>
        </div>
        <div className="md:hidden flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Uninterrupted Communication
          </div>
        </div>
        <div className="md:hidden flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Solution with Integrity
          </div>
        </div>
      </div>

      <div className="hidden md:flex flex-1 flex-col justify-center items-center gap-y-2 md:gap-y-14 z-40">
        <div className="flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Substantial Cost Savings
          </div>
        </div>
        <div className="flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Uninterrupted Communication
          </div>
        </div>
        <div className="flex flex-1 bg-opacity-20 md:w-6/12 w-10/12 bg-white max-h-20 shadow-2xl rounded-2xl center">
          <div className="flex-none flex w-7 mx-7 items-center justify-center">
            <img className="mx-auto" src={logo} />
          </div>
          <div className="flex flex-1 items-center">
            Solution with Integrity
          </div>
        </div>
      </div>
    </>
  );
}

export default Catalyst;
