function Description({ title, gradientTitle, subtitle, closingText, text }) {
  return (
    <>
      <div className="flex-col md:mx-20 lg:ml-36 mx-10 z-50">
        <div className="text-center md:text-left leading-tight font-semibold">
          <span className="text-2xl md:text-5xl we leading-tight tracking-wide">
            {title}
          </span>
          <br />
          <span className="text-2xl md:text-5xl uppercase bg-custom-gradient text-transparent bg-clip-text leading-tight tracking-wide">
            {gradientTitle}
          </span>
        </div>
        <div className="text-2xl font-medium flex my-5 tracking-wide text-left">
          <span className="leading-tight">{subtitle}</span>
        </div>
        <div className="text-sm flex text-left tracking-wide font-medium">
          <span className="leading-tight">{text}</span>
        </div>
        <div className="mt-2 text-sm flex text-left tracking-wide font-medium">
          <span className="leading-tight">{closingText}</span>
        </div>

        <div className="flex items-center justify-items-center sm:items-start sm:justify-items-start">
          <button className="transition duration-700 ease-in-out font-light text-xs font-semibold px-8 py-3 bg-cadetblue text-ghostwhite mt-4 hover:bg-transparent hover:text-cadetblue border border-cadetblue hover:border-cadetblue rounded-lg">
            Learn More
          </button>
        </div>
      </div>
    </>
  );
}
export default Description;
