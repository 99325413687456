import logo from "../assets/apex-primary-1@2x.png";

function Nav() {
  const handleButtonContact = () => {
    window.location.href = "#footer";
  };
  return (
    <>
      <div className="sticky top-0 flex md:flex bg-ghostwhite h-24 w-screen md:justify-between drop-shadow-lg items-center justify-center max-w-full z-50">
        <div className="flex-none w-32 ml-20 md:block hidden h-auto justify-items-start">
          <img src={logo} alt="" />
        </div>
        <div className="flex flex-wrap md:flex-wrap md:justify-items-end justify-center text-xs align-middle items-center md:mr-40 md:text-sm">
          <a
            className="hover:text-cadetblue hover:shadow-inner hover:bg-lavender transition duration-700 ease-in-out px-5 py-2 font-semibold rounded-lg"
            href="#"
          >
            Home
          </a>
          <a
            className="hover:text-cadetblue hover:shadow-inner hover:bg-lavender transition duration-700 ease-in-out px-5 py-2 font-semibold rounded-lg"
            href="#about"
          >
            About
          </a>
          <a
            className="hover:text-cadetblue hover:shadow-inner hover:bg-lavender transition duration-700 ease-in-out px-5 py-2 font-semibold rounded-lg"
            href="#services"
          >
            Services
          </a>
          <a
            className="hover:text-cadetblue hover:shadow-inner hover:bg-lavender transition duration-700 ease-in-out px-5 py-2 font-semibold rounded-lg"
            href="#partnership"
          >
            Partnership
          </a>
          <a
            className="hover:text-cadetblue hover:shadow-inner hover:bg-lavender transition duration-700 ease-in-out px-5 py-2 font-semibold rounded-lg"
            href="#work"
          >
            Work
          </a>
          <a
            className="hover:text-cadetblue hover:shadow-inner hover:bg-lavender transition duration-700 ease-in-out px-5 py-2 font-semibold rounded-lg"
            href="#founders"
          >
            Founders
          </a>
          <button
            onClick={handleButtonContact}
            className="transition duration-700 ease-in-out hover:bg-ghostwhite hover:shadow-inner hover:text-black px-7 py-2 rounded-lg bg-steelblue text-ghostwhite font-semibold"
          >
            {" "}
            Contact
          </button>
        </div>
      </div>
    </>
  );
}

export default Nav;
