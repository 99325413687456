import img1 from "../assets/ai_services.PNG";
import dataAnalysis from "../assets/data-analysis.gif";
import collect from "../assets/fast-data-collection.gif";
import drone from "../assets/drone.gif";
import engineerSerivces from "../assets/engr-serv.gif";
import webDevelop from "../assets/web-dev.gif";

function Services({ title, gradientTitle, subtitle, closingText, text }) {
  return (
    <>
      <div className="flex flex-1 flex-col items-center">
        <div className="text-center md:text-left my-10">
          <span className="text-2xl md:text-5xl uppercase font-semibold leading-tight">
            Our{" "}
          </span>
          <span className="text-2xl md:text-5xl uppercase bg-custom-gradient font-semibold text-transparent bg-clip-text leading-tight">
            Services
          </span>
        </div>

        <div className="flex flex-1 justify-evenly flex-row md:min-w-full mx-2 lg:mx-0">
          <div className="flex flex-col justify-evenly my-2">
            <div className="flex flex-col flex-1">
              <div className="flex flex-1 max-w-full min-w-fit">
                <img className="rounded-t-2xl w-full" src={dataAnalysis} />
              </div>
              <div className=" flex flex-none flex-col text-left justify-start py-6 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl">
                <div className="flex flex-col justify-start mx-5">
                  <div className="justify-start text-sm flex">
                    <span className="leading-tight">Data Analysis</span>
                  </div>
                  <div className="text-xs flex">
                    <span className="leading-tight">
                      Enhance machine learning through data annotation.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col flex-1">
              <div className="flex flex-1 max-w-full min-w-fit">
                <img className="rounded-t-2xl w-full" src={webDevelop} />
              </div>
              <div className="flex flex-none flex-col text-left justify-start py-6 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl">
                <div className="flex-col justify-start mx-5">
                  <div className="justify-start text-sm flex">
                    <span className="leading-tight">Web Development</span>
                  </div>
                  <div className="text-xs flex">
                    <span className="leading-tight">
                      Precise measurement services executed by Japanese
                      engineers.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-end m-2">
            <div className="flex flex-1 max-w-full min-w-fit">
              <img className="rounded-t-2xl w-full" src={collect} />
            </div>

            <div className="flex flex-none flex-col text-left justify-start py-6 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl">
              <div className="flex-col justify-start mx-5">
                <div className="justify-start text-sm flex">
                  <span className="leading-tight">Fast Data Collection</span>
                </div>
                <div className="text-xs flex">
                  <span className="leading-tight">
                    Timely and accurate data is provided via advanced
                    technology.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-evenly mt-2">
            <div className="flex flex-1 max-w-full min-w-fit">
              <img className="rounded-t-2xl w-full" src={drone} />
            </div>

            <div className="flex flex-1 flex-col text-left justify-start py-6 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl mb-2">
              <div className="flex-col justify-start mx-5">
                <div className="justify-start text-sm flex">
                  <span className="leading-tight">
                    Industrial Drone Support
                  </span>
                </div>
                <div className="text-xs flex">
                  <span className="leading-tight">
                    Harness the potential of drone technology for various
                    applications.
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-1 max-w-full min-w-fit">
              <img className="rounded-t-2xl w-full" src={engineerSerivces} />
            </div>
            <div className="flex flex-1 flex-col text-left justify-start py-6 opacity-80 bg-ghostwhite rounded-xl drop-shadow-2xl mb-2">
              <div className="flex-col justify-start mx-5">
                <div className="justify-start text-sm flex">
                  <span className="leading-tight">Ai Services</span>
                </div>
                <div className="text-xs flex">
                  <span className="leading-tight">Engineering Services</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;
